import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import Container from 'components/container'

const NavLink = styled(Link)({
  paddingTop: '8px',
  paddingBottom: '8px',
  color: 'black',
  '&:hover': {
    borderBottom: '2px solid',
  },
})

const Header = () => {
  return (
    <header
      css={{
        maxWidth: '100%',
      }}
    >
      <Container>
        <nav
          css={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Link to="/">
            <h1>Kirby Cool</h1>
          </Link>
          <div>
            <NavLink to="/biking">Biking</NavLink>
          </div>
        </nav>
      </Container>
    </header>
  )
}

export default Header
