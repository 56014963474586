import React from 'react'
import { Global } from '@emotion/core'
import { Link } from 'gatsby'

import Container from 'components/container'
import Header from 'components/header'
import reset from 'utils/reset'
import { rhythm, scale } from 'utils/typography'

const Layout = ({ location, title, children }) => {
  return (
    <>
      <Global styles={reset} />
      <Header />
      <main>
        <Container>{children}</Container>
      </main>
    </>
  )
}

export default Layout
