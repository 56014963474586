export default {
  a: {
    textDecoration: 'none',
    color: '#0000EE',
    '&:hover, &:focus': {
      color: '#573EDE',
    },
    '&:visited': {
      color: '#0000EE',
    },
  },
}
